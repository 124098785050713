import { Student } from "../components/Interfaces";

export default function stringifyAgg(agg?: number | string){
    var result: string = 'r';
    switch (String(agg)) {
        case '9':
            result = 'F9';
            break;
        case '8':
            result = 'P8';
            break;
        case '7':
            result = 'P7';
            break;
        case '6':
            result = 'C6';
            break;
        case '5':
            result = 'C5';
            break;
        case '4':
            result = 'C4';
            break;
        case '3':
            result = 'C3';
            break;
        case '2':
            result = 'D2';
            break;
        case '1':
            result = 'D1';
            break;
        case undefined:
            result = 'N/A';
            break;
    
        default:
            result = '';
            break;
    }
    return result;
}

export function notDeleted (s: Student) {
    if (s.deleted_at && s.deleted_at.length > 0) {
        return false
    }
    return true;
}