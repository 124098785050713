import { useState } from "react";
import Template from "../Template";
import { multipartApi, spinner } from "../../utils/customizeAxios";

export default function BatchPhotoUpload(){
    return(<Template children={<Page />} />);
}

function Page() {
    const [photos, setPhotos] = useState<File[]>([])
    const [uploadResponse, setUploadResponse] = useState<UploadResultInterface>()
    const [isLoading, setIsLoading] = useState(false)

    async function handleFileUpload() {
        if(photos.length < 0) {
            alert("Please select some photos")
            return
        }

        setIsLoading(true)
        await multipartApi.post("/batchPhotoUpload", {files: photos})
        .then(r => {
            console.log(r.data)
            setUploadResponse(r.data)
        }).catch(e => {
            console.error(e)
            alert("Error, please check if the files got uploaded if not try again")
        })
        setIsLoading(false)
    }

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if(!e.target.files || e.target.files.length < 0) {
            alert("Please select some photos")
            return
        }

        const selectedFiles = Array.from(e.target.files);
        setPhotos(selectedFiles);
    }

    return (
        <div className="default-container">
            <section className="flex flex-col items-center justify-center">
            <div>
            <h2 className="text-center font-bold text-xl block">Upload student photos with specific names</h2>
            <div className="flex justify-center flex-col">
              <input className="inputstyle" type="file" multiple onChange={e => handleFileChange(e)} />

              {isLoading ?
                <p className="inputstyle m-4 mx-6">{spinner}</p>
              :
                <button className="inputstyle m-4 mx-6" onClick={handleFileUpload}>Upload</button>
              }
            </div>
              {uploadResponse && <UploadResult r={uploadResponse} />}
            </div>
        </section>
        </div>
    )
}

interface UploadResultInterface {
    uploaded: number
    duplicates: number
    not_found: number
    successful: number
    failed: number
}
//['uploaded' => $uploaded, 'duplicates' => $duplicate, 'not_found' => $notFound, 'successful' => $successful, 'failed' => $uploaded - $successful];

function UploadResult({r}: {r: UploadResultInterface}){
    return(
      <table className="w-full text-purple-700">
        <p className="text-center text-xl font-bold">Upload result</p>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Uploaded
            </th>
            <td>{r.uploaded}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Succesful
            </th>
            <td>{r.successful}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Failed
            </th>
            <td>{r.failed}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Not found
            </th>
            <td>{r.not_found}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Duplicates
            </th>
            <td>{r.duplicates}</td>
        </tr>
      </table>
    )
  }