import { useEffect, useState } from "react";
import { Student, imgPlaceholder } from "../Interfaces";
import axios from "axios";
import NavBar from "../dashboard/NavBar";
import { useParams, Link, useNavigate } from "react-router-dom";
import { api } from "../../utils/customizeAxios";
import { permitted_everywhere, register_student } from "../../Permissions";
import { useAuth } from "../../AuthContext";

export default function Students(){
    const {classId} = useParams();
    return( <NavBar chilren={<Page />} classId={classId} />)
}


enum comp {
    current = "class/students",
    deleted = "class/students/deleted"
}
function Page(){
    const [students, setStudents] = useState<Student[]>();
    const [completion, setCompletion] = useState(comp.current)
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const {classId} = useParams();
    const {authUser} = useAuth()
    
    const fetchStudents = () => {
        axios.get(`${apiUrl}/${completion}/${classId}`)
        .then(r => {
            setStudents(r.data);
        }).catch(e => console.error("Failed to fetch: " + e));
    };

    const handleDelete = (id: string) => {
        if(!window.confirm('Are you sure?')) return
        api.delete('/students/'+ id)
        .then( () => {
            alert('Deleted succesfuly')
            fetchStudents()
        })
        .catch(e => {
            if(e.response.data)
                alert(e.response.data.message)
            else
                alert("Failed to delete, please check your network")
        })
    }

    const handleRestore = (id: string) => {
        if(!window.confirm('Are you sure?')) return
        api.patch('/students/restore/'+ id)
        .then( () => {
            alert('Restored succesfuly')
            fetchStudents()
        })
        .catch(e => {
            if(e.response.data)
                alert(e.response.data.message)
            else
                alert("Failed to restore, please check your network")
        })
    }

    useEffect(() => {
        fetchStudents()
    }, [completion]);

    if(!authUser){
        return(<div className="text-center text-xl">Please wait... Make sure you are logged in</div>)
    }
    
    return(
        <div className="rounded-lg w-[98%] min-h-[85%] ring-purple-600 mt-2 p-2 mx-auto bg-purple-100 ring-1 overflow-x-auto">
            <div className="flex justify-center p-2">
                    <button onClick={() => setCompletion(comp.current)} className={`inputstyle mx-1 ${completion === comp.current && "bg-purple-300"}`}>Current</button>
                    <button onClick={() => setCompletion(comp.deleted)} className={`inputstyle mx-1 ${completion === comp.deleted && "bg-purple-300"}`}>Deleted</button>
                </div>
            <table className="table-auto w-full">
                <thead>
                    <tr className="backdrop-blur-md text-left bg-purple-300">
                        <th className="p-3">Name</th>
                        <th>Sex</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {students?.map(student => 
                    <tr className="border-b-2 border-purple-400 hover:bg-purple-300">
                        <Link to={"/student/" + student.id}>
                        <td className="flex p-2">
                            <span className="w-7 h-7">
                            {student.profile_pic_filepath === null ? 
                                imgPlaceholder
                            :
                            <img src={`${imgUrl}/${student.profile_pic_filepath}`} className="w-7 h-7 rounded-full mr-1" alt="photo" />
                        }
                        </span>
                                {student.name}
                        </td>
                        </Link>
                        <td>{student.sex?.toUpperCase()}</td>
                        <td className="flex">
                            {permitted_everywhere.includes(authUser?.role!) &&
                            completion === comp.current ?
                                <button className="inputstyle mr-2" onClick={() => handleDelete(String(student.id!))}>Delete</button>
                                :
                                <button className="inputstyle mr-2" onClick={() => handleRestore(String(student.id!))}>Restore</button>
                            }
                            {register_student.includes(authUser?.role!) && completion === comp.current &&
                                <Selector student={student}/>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export function Selector({student}: {student: Student}){
    const navigate = useNavigate();
    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if(value === '')
            console.log('No value chozen');
        else
            navigate(`/student/reportcard/${student.id}/${e.target.value}`);
    }

    return(
            <div className="mt-4 flex flex-col">
                {/*<label htmlFor="period">ReportCard:</label>*/}
                <select className="inputstyle" name="period" onChange={e => handleInputChange(e)}>
                    <option value=''>ReportCard</option>
                    {student.periods?.map(period => 
                        <option value={period.id}>{period.name} {period.date_from?.split('-')[0]}</option>    
                    )}
                </select>
            </div>
    )
}