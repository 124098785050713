import { useState } from "react";
import NavBar from "../dashboard/NavBar";
import { useParams } from "react-router-dom";
import { multipartApi } from "../../utils/customizeAxios";

export default function BatchClassRegistration(){
  const {classId} = useParams();
  return( <NavBar chilren={<Page />} classId={classId} />)
}


interface UploadResultInterface {
  succesful: number
  uploaded: number
  failed: number
  duplicates: number
  invalid_section: number
  invalid_sex: number
}
  

function Page(){
  const {classId} = useParams();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [responseData, setResponseData] = useState<UploadResultInterface>();
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.files){
      alert("Please select a file.");
      return;
    }
    setSelectedFile(e.target.files[0]);
  };
  
  const handleFileUpload = async () => {
    setIsUploading(true)

    if(!selectedFile) {
      alert("No file selected yet");
      return
    };
    const formData = new FormData()
    formData.append('excelfile', selectedFile);

    await multipartApi.post('/class/registerStudentsViaExcel/' + classId, formData)
    .then(response => {
        setResponseData(response.data);    
      }).catch (error => {
        if(error.response.data)
          alert(error.response.data.message);
        else{
          console.error(error);
          alert('Error uploading file');
        }
      });

      setIsUploading(false)
    };
  
  return (
    <div className="grid grid-cols-1 h-screen gap-2">
      <section className="default-container flex flex-col items-center justify-center">
        <div>
        <h2 className="text-center font-bold text-xl block">Upload Excel File with student data</h2>
        <div className="flex justify-center flex-col">
          <input className="inputstyle" type="file" accept=".xlsx, .xls, .ods" onChange={e => handleFileChange(e)} />
          
          {isUploading ? 
            <p className="inputstyle m-4 mx-6 cursor-progress">Uploading...</p>
          :
            <button className="inputstyle m-4 mx-6" onClick={handleFileUpload}>Upload</button>
          }
        </div>
          {responseData && <UploadResult r={responseData} />}
        </div>
          <Guidelines />
      </section>
    </div>
  );
}


function UploadResult({r}: {r: UploadResultInterface}){
    return(
      <table className="w-full text-purple-700">
        <p className="text-center text-xl font-bold">Upload result</p>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Uploaded
            </th>
            <td>{r.uploaded}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Successful
            </th>
            <td>{r.succesful}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Failed
            </th>
            <td>{r.failed}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Duplicates
            </th>
            <td>{r.duplicates}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Invalid section(Day / Boarding)
            </th>
            <td>{r.invalid_section}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Invalid sex(m / f)
            </th>
            <td>{r.invalid_section}</td>
        </tr>
      </table>
    )
  }
  
  function Guidelines() {
    return(
      <div className="ring-1 md:w-3/4 w-full p-2 rounded-md bg-white ring-purple-500">
        <p className="text-center text-xl font-bold text-slate-700">Guidelines for the excel upload</p>
        <table className="w-full">
          <tr>
            <th className="border-[1px] border-black">Name</th>
            <th className="border-[1px] border-black">Sex(optional)</th>
            <th className="border-[1px] border-black">Section(optional)</th>
          </tr>
          <tr>
            <td className="border-[1px] border-black">kitiibwa mercy</td>
            <td className="border-[1px] border-black">m</td>
            <td className="border-[1px] border-black">Boarding</td>
          </tr>
        </table>
  
        <tr>
          <th>Name(compulsary):</th><td> Surname and last name either in capital or each begining with capital</td>
        </tr>
        <tr>
          <th>Sex(optional):</th>
          <td> Either 'm' or 'f'</td>
        </tr>
        <tr>
          <th>Section(optional):</th><td> Either 'Boarding' or 'Day'</td>
        </tr>
        <p className="mt-2"><span className="font-bold">NB:</span>The machine will start reading from the table cell
        that contains the heading 'NAME'. Make sure you have it in the first column</p>
      </div>
    )
  }