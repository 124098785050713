import { useEffect, useState } from "react";
import { Payment, Student } from "../Interfaces";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface paymentProps{
    student: Student;
    payment: Payment;
    repull: boolean;
    setRepull: any;
}
export default function EditPayment(p: paymentProps){
    const [payment, setPayment] = useState<Payment>(p.payment);
    const [formHidden, setFormHidden] = useState<string>('hidden')
    const [msg, setMsg] = useState<string>("This action can't be reversed so be extra careful")

    useEffect(() => {
        setPayment(p.payment)
    }, [p.payment])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setPayment({...payment, [name]: value});
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios.patch(apiUrl + '/payment/' + payment.id, payment)
        .then(r => {
            console.log(r);
            setPayment({});
            setMsg('updated Succesfuly');
            setFormHidden('hidden');
            p.setRepull(!p.repull);
        }).catch(e => {
            console.error(e);
            if(e.response)
                alert(e.response.data.error)
            else
                alert('An error occured and nothing was updated, please try again')
        });
    };

    return(
        <div>
            <button className="shadowstyle-1" onClick={() => setFormHidden(formHidden === '' ? 'hidden' : '')}>
                Edit
            </button>

            <form className={`${formHidden}`} onSubmit={e => handleSubmit(e)} >
                <div className="error-msg">
                    {msg}
                </div>

                <div className="mt-4 flex flex-col">
                    <label htmlFor="name">Amount:</label>
                    <input className="inputstyle" 
                        type="number" name="amount" 
                        placeholder="Amount of money" value={payment.amount} 
                        onChange={e => handleInputChange(e)}
                    />
                </div>

                <div className="mt-4 flex flex-col">
                    <label htmlFor="name">Date paid:</label>
                    <input className="inputstyle" 
                        type="date" name="date_paid" 
                        value={payment.date_paid} 
                        onChange={e => handleInputChange(e)}
                    />
                </div>

                <div className="mt-4 flex flex-col" >
                    <label htmlFor="payement_method">Payment method</label>
                    <select className="inputstyle" name='payement_method' onChange={e => handleInputChange(e)} >
                        <option value="Cash">Cash</option>
                        <option value="Bank">Bank</option>
                        <option value="Mobile Money">Mobile Money</option>
                    </select>
                </div>

                <div className="mt-4 flex flex-col" >
                    <label htmlFor="period_id">Period</label>
                    <select className="inputstyle" name="period_id" onChange={e => handleInputChange(e)}>
                        <option value={payment.period_id}>{p.student.periods && payment.period_id && p.student.periods?.find(p => p.id === payment.period_id)?.name} </option>
                        {p.student.periods?.map(period =>
                            <option value={period.id}>
                                {period.name} - {period.date_to && period.date_to.split('-')[0]}
                            </option>
                        )}
                    </select>
                </div>

                <div className="mt-4 flex flex-col">
                    <label htmlFor="name">Reason:</label>
                    <input className="inputstyle" 
                        type="text" name="reason" 
                        placeholder="Any extra details about payment" value={payment.reason} 
                        onChange={e => handleInputChange(e)}
                    />
                </div>

                <input className="inputstyle mt-4 mb-2" type="submit" value="Submit" />

            </form>
        </div>
    );
}