import { useState } from "react";
import { multipartApi } from "../../../utils/customizeAxios";

interface UploadResultInterface {
    succesful: number;
    uploaded: number;
    failed: number;
    invalid_agg: number;
}
  

export default function ExcelAggregates(
  {classId, updated, setUpdated}: {classId: string, updated: boolean, setUpdated: any}
){
    const [selectedFile, setSelectedFile] = useState<File>();
    const [responseData, setResponseData] = useState<UploadResultInterface>();
    
      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){
            alert("Please select a file.");
            return;
        }
        setSelectedFile(e.target.files[0]);
      };
    
      const handleFileUpload = async () => {
        if(!selectedFile) {
            alert("No file selected yet");
            return
        };
          const formData = new FormData()
          formData.append('excelfile', selectedFile);
    
          await multipartApi.post('/gradings/createViaExcel/' + classId, formData)
          .then(response => {
                setUpdated(!updated);
                setResponseData(response.data);    
            }).catch (error => {
                if(error.response.data)
                    alert(error.response.data.message);
                else{
                    console.error(error);
                    alert('Error uploading file');
                }
            });
        };
    
      return (
        <div className="grid grid-cols-1 h-screen gap-2">
            <section className="default-container flex flex-col items-center justify-center">
                <div>
                <h2 className="text-center font-bold text-xl block">Upload Excel File with grading data</h2>
                <div className="flex justify-center flex-col">
                  <input className="inputstyle" type="file" accept=".xlsx, .xls, .ods" onChange={e => handleFileChange(e)} />
                  <button className="inputstyle m-4 mx-6" onClick={handleFileUpload}>Upload</button>
                </div>
                  {responseData && <UploadResult r={responseData} />}
                </div>
                  <Guidelines />
            </section>
        </div>
      );
}


function UploadResult({r}: {r: UploadResultInterface}){
    return(
      <table className="w-full text-purple-700">
        <p className="text-center text-xl font-bold">Upload result</p>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Uploaded
            </th>
            <td>{r.uploaded}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Successful
            </th>
            <td>{r.succesful}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Failed
            </th>
            <td>{r.failed}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Invalid aggregate
            </th>
            <td>{r.invalid_agg}</td>
        </tr>
      </table>
    )
  }
  
  function Guidelines() {
    return(
      <div className="ring-1 md:w-3/4 w-full p-2 rounded-md bg-white ring-purple-500">
        <p className="text-center text-xl font-bold text-slate-700">Guidelines for the excel upload</p>
        <table className="w-full">
          <tr>
            <th className="border-[1px] border-black">FROM</th>
            <th className="border-[1px] border-black">TO(0-100)</th>
            <th className="border-[1px] border-black">AGGREGATE(1-9)</th>
            <th className="border-[1px] border-black">REMARK</th>
          </tr>
          <tr>
            <td className="border-[1px] border-black">0</td>
            <td className="border-[1px] border-black">34</td>
            <td className="border-[1px] border-black">9</td>
            <td className="border-[1px] border-black">More effort needed</td>
          </tr>
        </table>
  
        <tr>
          <th>FROM:</th><td> Marks from(0-100)</td>
        </tr>
        <tr>
          <th>TO:</th><td>Marks to(0-100)</td>
        </tr>
        <tr>
          <th>AGGREGATE:</th><td>Must be a number(1-9)</td>
        </tr>
        <p className="mt-2"><span className="font-bold">NB:</span>The machine will start reading from the table cell
        that contains the heading 'FROM'. Make sure you have it in the first column</p>
      </div>
    )
  }