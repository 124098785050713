import { useEffect, useState } from "react";
import { Divisioning } from "../Interfaces";
import axios from "axios";
import { useParams } from "react-router-dom";
import Template from "../Template";
const apiUrl = process.env.REACT_APP_API_URL;

export default function DivisionView(){
    return(<Template children={<Page />} />)
}

function Page(){
    const [divisions, setDivisions] = useState<Divisioning[]>([]);
    const [updated, setUpdated] = useState<boolean>(true)
    const {classId} = useParams();

    const fetchAggs = () => {
        axios.get(`${apiUrl}/divisions/${classId}`)
        .then(r => {
            setDivisions(r.data);
        }).catch(e => console.error("Failed to fetch: " + e));
    };

    useEffect(() => {
        fetchAggs();
    }, [updated]);
    
    console.log(divisions)
    return(
            <div className="">
                <div className="rounded-lg w-[98%] min-h-[97%] ring-purple-600 mt-2 p-2 mx-auto bg-purple-100 ring-1 overflow-x-auto">
                    <table className="w-full">
                        <thead className="sticky top-0">
                            <tr className="backdrop-blur-md text-left bg-purple-300/50">
                                <th className="p-3">
                                    <span>From</span> - <span>To</span>
                                </th>
                                <th>Division</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {divisions?.map(division => 
                            <DataRow key={division.id} setUpdated={setUpdated} updated={updated} division={division} />
                        )}
                        <CreateNew setUpdated={setUpdated} updated={updated} classId={classId}/>
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

interface CreateProps{
    division?: Divisioning;
    updated: boolean;
    update?: boolean;
    setUpdated: any;
    setUpdate?: any;
    classId?: string
}
function CreateNew(p: CreateProps){
    const [division, setDivision] = useState<Divisioning>({});

    const handleSubmit = () => {
        if(division.aggs_from && division.aggs_to && division.division){
            axios.post(`${apiUrl}/divisions/${p.classId}`, division)
            .then(response => {
                console.log(response.data); 
                p.setUpdated(!p.updated);
                setDivision({});
            })
            .catch(e => console.error(e));
        }
        else
            alert('fill all fields first');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setDivision({...division, [name]: value,});
    };
    return (
        <tr className="border-b-2 border-purple-400 hover:bg-purple-300">
            <td className="p-2">
                <input type="number" className="ring-1 rounded-md ring-slate-500 pl-2" name="aggs_from" required placeholder="from" value={division?.aggs_from} onChange={e => handleInputChange(e)}/> - 
                <input type="number" name="aggs_to" required className="ring-1 rounded-md ring-slate-500 pl-2" placeholder="to" value={division?.aggs_to} onChange={e => handleInputChange(e)}/>
            </td>
            <td>
                <select name="division" required className="inputstyle w-1/2" value={division?.division} onChange={e => handleInputChange(e)}>
                    <option>--select--</option>
                    <option value='I'>I</option>
                    <option value='II'>II</option>
                    <option value='III'>III</option>
                    <option value='IV'>IV</option>
                    <option value='U'>U</option>
                </select>    
            </td>
            <td><input type="submit" name="submit" value={"Submit"} className="ring-1 p-1 bg-purple-200 ring-slate-500 hover:bg-purple-400 rounded-md" onClick={() => handleSubmit()}/></td>
        </tr>
    );
}

function DataRow(p: CreateProps){
    const [division, setDivision] = useState<Divisioning>({});
    const [update, setUpdate] = useState<boolean>(false)
    useEffect(() => {
        p.division && setDivision(p.division)
    }, [division]);

    const handleDelete = (id: number) => {
        window.confirm('are you sure') &&
        axios.delete(`${apiUrl}/divisions/${id}`)
        .then(response => {
            console.log(response.data); 
            p.setUpdated(!p.updated);
        })
        .catch(e => console.error(e));
    };

    return(
    update ? 
        <UpdateRow division={division} update={update} setUpdate={setUpdate} updated={p.updated} setUpdated={p.setUpdated} />
    :
        <tr className="border-b-2 border-purple-400 hover:bg-purple-300" key={division.id}>
            <td className="p-2">{division.aggs_from} - {division.aggs_to}</td>
            <td>{division.division}</td>
            <td className="flex justify-around">
                <button className="ring-1 p-1 ring-slate-500 hover:bg-slate-50 rounded-md" onClick={() => setUpdate(!update)}>Edit</button>
                <button className="ring-1 p-1 ring-purple-500 bg-red-300 hover:bg-slate-50 rounded-md" onClick={() => division.id && handleDelete(division.id)}>Delete</button>
            </td>
        </tr>
    );
}

function UpdateRow(p: CreateProps){
    const [division, setDivision] = useState<Divisioning>();
    useEffect(() => setDivision(p.division), []);

    const handleSubmit = () => {
        if(division && division.aggs_from && division.aggs_to && division.division){
            axios.patch(`${apiUrl}/divisions/${division.id}`, division)
            .then(response => {
                console.log(response.data); 
                p.setUpdated(!p.updated);
                p.setUpdate(!p.update);
                setDivision({});
            })
            .catch(e => console.error(e));
        }
        else
            alert('fill all fields first');
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setDivision({...division, [name]: value,});
    };
    return (
        <tr className="border-b-2 border-purple-400 hover:bg-purple-300">
                <td className="p-2">
                    <input type="number" className="ring-1 rounded-md ring-slate-500 pl-2" name="aggs_from" required placeholder="from" value={division?.aggs_from} onChange={e => handleInputChange(e)}/> - 
                    <input type="number" name="aggs_to" required className="ring-1 rounded-md ring-slate-500 pl-2" placeholder="to" value={division?.aggs_to} onChange={e => handleInputChange(e)}/>                </td>
                <td>
                    <select name="division" required className="inputstyle w-1/2" value={division?.division} onChange={e => handleInputChange(e)}>
                        <option>--select--</option>
                        <option value='I'>I</option>
                        <option value='II'>II</option>
                        <option value='III'>III</option>
                        <option value='IV'>IV</option>
                        <option value='U'>U</option>
                    </select>      
                </td>
                <td><input type="submit" name="submit" value={"Update"} className="ring-1 p-1 bg-purple-200 ring-slate-500 hover:bg-purple-400 rounded-md" onClick={() => handleSubmit()}/></td>
        </tr>
    );
}