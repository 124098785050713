import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SchoolClass, User, handleInputChange } from "../Interfaces";
import axios from "axios";
import NavBar from "../dashboard/NavBar";
import { multipartApi } from "../../utils/customizeAxios";
import { useAuth } from "../../AuthContext";
import { permitted_everywhere, record_payment, register_staff } from "../../Permissions";
const apiUrl = process.env.REACT_APP_API_URL;


export default function ClassSettings(){
    const {classId} = useParams();
    return( <NavBar chilren={<Page />} classId={classId} />)
}

function Page(){
    const { authUser } = useAuth();
    const [clas, setClas] =useState<SchoolClass>({name: ''});
    const {classId} = useParams();

    useEffect(() => {
        axios.get(apiUrl + '/class/' + classId)
        .then(r => setClas(r.data))
        .catch(e => console.error(e));
    }, [classId]);

    return(        
        <div className="grid md:lg:grid-cols-2 gap-2">
            {register_staff.includes(authUser?.role!) && 
                <div className="default-container">
                    <SetClassTeacher clas={clas} setclas={setClas} />
                </div>
            }
            {record_payment.includes(authUser?.role!) && 
                <div className="default-container">
                    <SetSchoolFees clas={clas} setclas={setClas} />
                </div>
            }
            <div className="default-container col-span-2">
                <UploadMarksViaExcel />
            </div>
        </div>
    );
}

interface componentsProps {
    clas: SchoolClass;
    setclas: React.Dispatch<React.SetStateAction<SchoolClass>>;
}
function SetSchoolFees(p: componentsProps) {
    const [msg, setmsg] = useState<string>('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios.post(apiUrl + "/class/update/" + p.clas.id, p.clas)
        .then(r => setmsg("Updated"))
        .catch(e => console.error(e));
    };

    return (
        <form className="" onSubmit={e => handleSubmit(e)}>
            <div className="text-xl text-slate-500">Set schoolfees for {p.clas.name}</div>
            <div className="error-msg">{msg}</div>
            <div className="mt-4">
                <div>
                    <label htmlFor="fees_boarding">Boarding: </label>
                    <input className="inputstyle" 
                        type="number" name="fees_boarding" 
                        placeholder="Bording" value={p.clas?.fees_boarding && Math.round(p.clas?.fees_boarding)} 
                        onChange={e => handleInputChange(e, p.clas, p.setclas)}
                    />
                </div>
                <div>
                    <label htmlFor="fees_day">Day: </label>
                    <input className="inputstyle mt-2" 
                        type="number" name="fees_day" 
                        placeholder="Day" value={p.clas?.fees_day && Math.round(p.clas?.fees_day)} 
                        onChange={e => handleInputChange(e, p.clas, p.setclas)}
                    />
                </div>
            </div>
            <input className="inputstyle mt-4 mb-2" type="submit" value="Change" />
        </form>
    )
}


function SetClassTeacher(p: componentsProps) {
    const [teachers, setTeachers] = useState<User[]>([]);
    const [msg, setmsg] = useState<string>('');
    useEffect(() => {
        axios.get(apiUrl + '/teachers').then(r => setTeachers(r.data))
        .catch(e => console.error(e));
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios.post(apiUrl + "/class/update/" + p.clas.id, p.clas)
        .then(r => setmsg("Updated"))
        .catch(e => console.error(e));
    };

    return (
        <form className="" onSubmit={e => handleSubmit(e)}>
            <div className="text-xl text-slate-500">Set Class teacher for {p.clas.name}</div> 
            <div className="error-msg">{msg}</div>
            <div className="mt-4 flex flex-col">
                <select className="inputstyle" name="classteacher_id" onChange={e => handleInputChange(e, p.clas, p.setclas)}>
                    <option value={p.clas.classteacher_id}>{p.clas.class_teacher?.name}</option>
                    {teachers.map(teacher => 
                        teacher.name.toLocaleLowerCase() !== "othieno simon" &&
                        <option value={teacher.id}>{teacher.name}</option>
                    )}
                </select>
            </div>
            <input className="inputstyle mt-4 mb-2" type="submit" value="Change" />
        </form>
    )
}

/*********************************************************************************************************** */
interface UploadResultInterface{
    invalid_names: string[];
    uploaded: number;
    succesful: number;
    failed: number;
}
function UploadMarksViaExcel(){
    const {classId} = useParams();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [markType, setMarkType] = useState('end');
    const [responseData, setResponseData] = useState<UploadResultInterface>();
    const [isUploading, setIsUploading] = useState(false)
    
      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){
            alert("Please select a file.");
            return;
        }
        setSelectedFile(e.target.files[0]);
      };
    
      const handleFileUpload = async () => {
        setIsUploading(true)

        if(!selectedFile) {
            alert("No file selected yet");
            return
        };

        if(!markType) {
            alert("Please select either 'End' or 'Mid' term again")
            return
        }
          const formData = new FormData()
          formData.append('excelfile', selectedFile);
    
          await multipartApi.post('/class/uploadMarksheetViaExcel/' + classId +'/'+ markType, formData)
          .then(response => {
                setResponseData(response.data);    
            }).catch (error => {
                if(error.response.data)
                    alert(error.response.data.message);
                else{
                    console.error(error);
                    alert('Error uploading file');
                }
            });

            setIsUploading(false)
        };
    
      return (
        <div className="">
            <section className="flex flex-col items-center justify-center">
                <div>
                <h2 className="text-center font-bold text-xl block">Upload Excel File with marksheet data</h2>
                <div className="flex justify-center flex-col">
                  <input className="inputstyle" type="file" accept=".xlsx, .xls, .ods" onChange={e => handleFileChange(e)} />
                  <select className="inputstyle mt-2" name="mark-type" value={markType} onChange={e => setMarkType(e.target.value)}>
                    <option value='end'>End of term</option>
                    <option value='mid'>Mid term</option>
                  </select>

                  { isUploading ?
                    <p className="inputstyle m-4 mx-6 cursor-progress">Please wait...</p>
                  :
                    <button className="inputstyle m-4 mx-6" onClick={handleFileUpload}>Upload</button>
                  }
                </div>
                  {responseData && <UploadResult r={responseData} />}
                </div>
                  <Guidelines />
            </section>
        </div>
      );
}

/******************************************************************************************************** */
function UploadResult({r}: {r: UploadResultInterface}){
    return(
      <table className="w-full text-purple-700">
        <p className="text-center text-xl font-bold">Upload result</p>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Uploaded
            </th>
            <td>{r.uploaded}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Successful
            </th>
            <td>{r.succesful}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Failed
            </th>
            <td>{r.failed}</td>
        </tr>
        <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
            <th className="p-3">
                Invalid names
            </th>
            <td>{r.invalid_names.map(name => name + ', ')}</td>
        </tr>
      </table>
    )
  }
  
  function Guidelines() {
    return(
      <div className="ring-1 md:w-3/4 w-full p-2 rounded-md bg-white ring-purple-500">
        <p className="text-center text-xl font-bold text-slate-700">Guidelines for the excel upload</p>
        <table className="w-full">
          <tr>
            <th className="border-[1px] border-black">NAME</th>
            <th className="border-[1px] border-black">ENGLISH(any subject name)</th>
            <th className="border-[1px] border-black">SCIENCE(any subject)</th>
          </tr>
          <tr>
            <td className="border-[1px] border-black">MUSOKE GEORGE</td>
            <td className="border-[1px] border-black">43</td>
            <td className="border-[1px] border-black">58</td>
          </tr>
        </table>
  
        <tr>
          <th>Name(compulsary):</th><td> Surname and last name either in capital or each begining with capital</td>
        </tr>
        <tr>
          <th>ANY-SUBJECT-NAME:</th>
          <td> Put any subject name that can be used to identify the subject</td>
        </tr>
        <tr>
          <th>SUBJECT-2:</th><td> Continue with subject names while putting students marks under</td>
        </tr>
        <p className="mt-2"><span className="font-bold">NB:</span>The machine will start reading from the table cell
        that contains the heading 'NAME'. Make sure you have it in the first column</p>
      </div>
    )
  }