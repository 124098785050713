import { ReactNode } from "react";
import Sidebar from "../Sidebar";
import whatsapp from "../images/whatsapp.png";
import maillogo from "../images/mail.jpeg";

export default function Template({children}: {children: ReactNode}){
    return(
            <main className="md:lg:flex">
                <Sidebar />
                    <div className="w-full">
                       {children}
                       <Footer />
                    </div>
            </main>
    );
}

function Footer(){
    return(
        <section className="print:hidden sticky -top-0 w-full bg-slate-200 text-center mt-4 p-2">
            <p>&#169; {new Date().getFullYear()} Seeta C / U primary School</p>
            <p>Designed &amp; Developed by <a href="https://othie12.github.io/potfolio" target="_blank" rel="noopener noreferrer" className="text-purple-700 font-bold">Othieno Simon</a></p>
            <div className="flex justify-around">
                <a href="https:wa.me/+256706648953?text=Hello their, got your contact from SeetaCUPS"><img src={whatsapp} className="w-10 rounded-full"/></a>
                <a href="mailto:simonothieno10@gmail.com"><img src={maillogo} className="w-10 rounded-lg"/></a>
            </div>
        </section>
    );
}