import { useEffect, useState } from "react";
import { SchoolClass } from "../Interfaces";
import axios from "axios";
import { Link } from "react-router-dom";
import Search from "../../Search";
import Template from "../Template";
import { permitted_everywhere, record_payment } from "../../Permissions";
import { useAuth } from "../../AuthContext";
import { spinner } from "../../utils/customizeAxios";

export default function Dashboard() {
    return(
        <Template children={<Page />} />
    ); 
}

enum comp {
    current = "/student/search/",
    deleted = "/student/softDeleted/search/"
}
function Page(){
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false)
    const [searchUrlCompletion, setSearchUrlCompletion] = useState(comp.current)
    const [classes, SetClasses] = useState<SchoolClass[]>([]);
    const {authUser} = useAuth();

    const fetchClasses = async () => {
        setIsLoading(true)

        await axios.get(`${apiUrl}/classes`)
        .then(response => {
            SetClasses(response.data);
        }).catch(e => console.error(e));

        setIsLoading(false)
    };

    useEffect(() => {
        fetchClasses()
    }, []);
    return(
        <div>
            <div className="mt-2 px-2">
                <Search searchUrl={apiUrl + searchUrlCompletion} redirectUrl={'/student/'} pholder={searchUrlCompletion === comp.current ? "Search Pupil" : "Search deleted pupil"} />
                {permitted_everywhere.includes(authUser?.role!) &&
                <div className="flex justify-center p-2">
                    <button onClick={() => setSearchUrlCompletion(comp.current)} className={`inputstyle mx-1 ${searchUrlCompletion === comp.current && "bg-purple-300"}`}>Current</button>
                    <button onClick={() => setSearchUrlCompletion(comp.deleted)} className={`inputstyle mx-1 ${searchUrlCompletion === comp.deleted && "bg-purple-300"}`}>Deleted</button>
                </div>
                }
            </div>
            {isLoading ?
            
            <div className="flex justify-center text-xl min-h-screen">
                <p>Please wait...</p>
                {spinner}
            </div>

            :

            <div className="grid md:lg:grid-cols-3 gap-4 p-4">
                {classes.map(clas => <ClassCard c={clas} key={clas.id} />)}
            </div>
            }
        </div>

    );
}

interface ClassCardProps{
    c: SchoolClass;
}
function ClassCard({c}: ClassCardProps){
    const girls = c.girls ? c.girls : 0;
    const boys = c.boys ? c.boys : 0;
    const {authUser} = useAuth();

    return (
        <Link to={"/class/students/" + c.id}>
            <div className="gradient1 transition duration-300 rounded-lg p-4 hover:bg-purple-500 shadowstyle-1" key={c.id}>
                <p className="font-bold bg-purple-300/30 p-2 rounded-lg text-gray-800">{c.name}</p>
                <p className="font-lighstickyt"><span className="font-bold">Classteacher: </span>{c.class_teacher?.name}</p>
                <p className="text-purple-900">Girls: {girls}</p>
                <p className="text-purple-900">Boys: {boys}</p>
                <p className="font-medium">Total: {girls + boys}</p>
                {authUser?.role && record_payment.includes(authUser.role) &&
                <p className="flex flex-col">
                    <span>Day: Ugx,{c.fees_day && Math.round(c.fees_day)}</span>
                    <span>Boarding: Ugx,{c.fees_boarding && Math.round(c.fees_boarding)}</span>
                </p>
                }
            </div>
        </Link>
    );
}

