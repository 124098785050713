//created this to let teacher's upload excel spreadsheets with student's info at once

import { useState } from "react";
import Template from "../Template";
import { multipartApi } from "../../utils/customizeAxios";

export default function UploadExcel() {
    return(<Template children={<Page />} />);
}

function Page(){
const [selectedFile, setSelectedFile] = useState<File>();
const [responseData, setResponseData] = useState<UploadResultInterface>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.files){
        alert("Please select a file.");
        return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if(!selectedFile) {
        alert("No file selected yet");
        return
    };
    try {
      const formData = new FormData()
      formData.append('excelfile', selectedFile);

      const response = await multipartApi.post('/batchExcelUpload', formData);

      if(response.status === 200){
        setResponseData(response.data);

      }

      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file:'+ error);
    }
  };

  return (
    <div className="grid grid-cols-1 h-screen gap-2">
        <section className="default-container flex flex-col items-center justify-center">
            <div>
            <h2 className="text-center font-bold text-xl block">Upload Excel File with student data</h2>
            <div className="flex justify-center flex-col">
              <input className="inputstyle" type="file" accept=".xlsx, .xls, .ods" onChange={e => handleFileChange(e)} />
              <button className="inputstyle m-4 mx-6" onClick={handleFileUpload}>Upload</button>
            </div>
              {responseData && <UploadResult r={responseData} />}
            </div>
              <Guidelines />
        </section>
    </div>
  );
}

interface UploadResultInterface {
  succesful: number
  uploaded: number
  failed: number
  invalid_class: number
  duplicates: number
  invalid_section: number
  invalid_sex: number
}

function UploadResult({r}: {r: UploadResultInterface}){
  return(
    <table className="w-full text-purple-700">
      <p className="text-center text-xl font-bold">Upload result</p>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Uploaded
          </th>
          <td>{r.uploaded}</td>
      </tr>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Successful
          </th>
          <td>{r.succesful}</td>
      </tr>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Failed
          </th>
          <td>{r.failed}</td>
      </tr>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Invalid class
          </th>
          <td>{r.invalid_class}</td>
      </tr>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Duplicates
          </th>
          <td>{r.duplicates}</td>
      </tr>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Invalid section(Day / Boarding)
          </th>
          <td>{r.invalid_section}</td>
      </tr>
      <tr className="backdrop-blur-md text-left bg-purple-300/50 border-b-2 border-purple-500">
          <th className="p-3">
              Invalid sex(m / f)
          </th>
          <td>{r.invalid_section}</td>
      </tr>
    </table>
  )
}

function Guidelines() {
  return(
    <div className="ring-1 md:w-3/4 w-full p-2 rounded-md">
      <p className="text-center text-xl font-bold text-slate-700">Guidelines for the excel upload</p>
      <table className="w-full">
        <tr>
          <th className="border-[1px] border-black">Name</th>
          <th className="border-[1px] border-black">Sex</th>
          <th className="border-[1px] border-black">Section</th>
          <th className="border-[1px] border-black">Class</th>
        </tr>
        <tr>
          <td className="border-[1px] border-black">kitiibwa mercy</td>
          <td className="border-[1px] border-black">m</td>
          <td className="border-[1px] border-black">Boarding</td>
          <td className="border-[1px] border-black">P.7</td>
        </tr>
      </table>

      <tr>
        <th>Name:</th><td> Surname and last name either in capital or each begining with capital</td>
      </tr>
      <tr>
        <th>Sex:</th>
        <td> Either 'm' or 'f'</td>
      </tr>
      <tr>
        <th>Section:</th><td> Either 'Boarding' or 'Day'</td>
      </tr>
      <tr>
        <th>Class:</th><td> exactly either "Baby", "Middle", Top", "P.1", "P.2".... "P.7"</td>
      </tr>
      <p className="mt-2"><span className="font-bold">NB:</span>Don't include any headings because they'll distort the auto-file reader,
      just start with the first name untill the last one or the header row</p>
    </div>
  )
}